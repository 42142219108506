import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import headerImage from "../../../../../assets/images/2025eventflowbg.png";
import RotatingCard from "../../../../../examples/Cards/RotatingCard";
import RotatingCardBack from "../../../../../examples/Cards/RotatingCard/RotatingCardBack";
import RotatingCardFront from "../../../../../examples/Cards/RotatingCard/RotatingCardFront";
import { firestore } from "../../../../../firebase";

// Convert 12-hour format (AM/PM) to 24-hour (military) format
const convertToMilitaryTime = (time12hr) => {
  if (!time12hr) return "";
  const [time, modifier] = time12hr.split(" ");
  let [hours, minutes] = time.split(":");
  if (modifier === "PM" && hours !== "12") {
    hours = (parseInt(hours) + 12).toString();
  }
  if (modifier === "AM" && hours === "12") {
    hours = "00";
  }
  return `${hours}:${minutes}`;
};

// Convert 24-hour time to 12-hour format (AM/PM)
const convertTo12HourFormat = (militaryTime) => {
  if (!militaryTime) return "";
  let [hours, minutes] = militaryTime.split(":");
  const modifier = hours >= 12 ? "PM" : "AM";
  if (hours > 12) hours -= 12;
  if (hours === "0" || hours === 0) hours = "12";
  return `${hours}:${minutes} ${modifier}`;
};

const Itinerary = ({ vendors, owner, event_date, eventId }) => {
  // Set default viewMode to "list"
  const [viewMode, setViewMode] = useState("list");
  
  const [itinerary, setItinerary] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  
  // Snackbar state for delete notifications
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // States for adding new item
  const [selectedVendor, setSelectedVendor] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [customVendor, setCustomVendor] = useState("");
  const [useCustomVendor, setUseCustomVendor] = useState(false);
  // Use raw URL state for the Add modal:
  const [rawAddUrl, setRawAddUrl] = useState("");
  const [addLinkConfirmed, setAddLinkConfirmed] = useState(false);

  // States for editing an item
  const [editingItem, setEditingItem] = useState(null);
  const [editSelectedVendor, setEditSelectedVendor] = useState("");
  const [editEventTime, setEditEventTime] = useState("");
  const [editCustomVendor, setEditCustomVendor] = useState("");
  const [editUseCustomVendor, setEditUseCustomVendor] = useState(false);
  // Use raw URL state for the Edit modal:
  const [rawEditUrl, setRawEditUrl] = useState("");
  const [editLinkConfirmed, setEditLinkConfirmed] = useState(false);

  // Custom sort: items with a valid time come first (sorted ascending), then items with empty time.
  const sortItinerary = (items) => {
    return items.sort((a, b) => {
      if (a.time === "" && b.time === "") return 0;
      if (a.time === "") return 1;
      if (b.time === "") return -1;
      return a.time.localeCompare(b.time);
    });
  };

  // Fetch itinerary from Firestore on mount/update
  useEffect(() => {
    const fetchItinerary = async () => {
      if (eventId) {
        const eventRef = collection(firestore, "Events", eventId, "Itinerary");
        const snapshot = await getDocs(eventRef);
        const itineraryData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setItinerary(sortItinerary(itineraryData));
      }
    };
    fetchItinerary();
  }, [eventId]);

  // Open/close Add Modal
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setSelectedVendor("");
    setEventTime("");
    setCustomVendor("");
    setUseCustomVendor(false);
    setRawAddUrl("");
    setAddLinkConfirmed(false);
  };

  // Open/close Edit Modal
  const handleOpenEditModal = (item) => {
    setEditingItem(item);
    if (vendors.find((v) => v.vendor_name === item.vendor)) {
      setEditSelectedVendor(item.vendor);
      setEditUseCustomVendor(false);
      setEditCustomVendor("");
    } else {
      setEditUseCustomVendor(true);
      setEditCustomVendor(item.vendor);
      setEditSelectedVendor("");
    }
    setEditEventTime(item.time);
    setRawEditUrl(item.link || "");
    setEditLinkConfirmed(!!item.link);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setEditingItem(null);
    setEditSelectedVendor("");
    setEditEventTime("");
    setEditCustomVendor("");
    setEditUseCustomVendor(false);
    setRawEditUrl("");
    setEditLinkConfirmed(false);
  };

  // Function to add a vendor to the itinerary
  const handleAddVendorToItinerary = async () => {
    if ((!selectedVendor && !useCustomVendor)) {
      alert("Please select or enter a vendor!");
      return;
    }
    // If eventTime is provided, convert it; otherwise use an empty string.
    const militaryTime = eventTime ? convertToMilitaryTime(eventTime) : "";
    const vendorName = useCustomVendor ? customVendor : selectedVendor;
    const vendor =
      vendors.find((v) => v.vendor_name === vendorName) || {
        vendor_name: vendorName,
        pictureURL: "",
      };
    const newItem = {
      vendor: vendor.vendor_name,
      image: vendor.pictureURL || headerImage,
      time: militaryTime,
      date: event_date,
      link: rawAddUrl, // Use the rawAddUrl (which should now include https:// if needed)
    };
    setItinerary((prevItinerary) => {
      const updatedItinerary = [...prevItinerary, newItem];
      return sortItinerary(updatedItinerary);
    });
    try {
      if (eventId) {
        const itineraryRef = collection(firestore, "Events", eventId, "Itinerary");
        await addDoc(itineraryRef, newItem);
        console.log("Itinerary item added to Firestore");
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    }
    handleCloseAddModal();
  };

  // Function to update an itinerary item
  const handleUpdateItineraryItem = async () => {
    if ((!editSelectedVendor && !editUseCustomVendor)) {
      alert("Please select or enter a vendor!");
      return;
    }
    const militaryTime = editEventTime ? convertToMilitaryTime(editEventTime) : "";
    const vendorName = editUseCustomVendor ? editCustomVendor : editSelectedVendor;
    const vendor =
      vendors.find((v) => v.vendor_name === vendorName) || {
        vendor_name: vendorName,
        pictureURL: "",
      };
    const updatedItem = {
      vendor: vendor.vendor_name,
      image: vendor.pictureURL || headerImage,
      time: militaryTime,
      date: event_date,
      link: rawEditUrl,
    };
    setItinerary((prevItinerary) =>
      sortItinerary(
        prevItinerary.map((item) =>
          item.id === editingItem.id ? { ...item, ...updatedItem } : item
        )
      )
    );
    try {
      const itemDocRef = doc(firestore, "Events", eventId, "Itinerary", editingItem.id);
      await updateDoc(itemDocRef, updatedItem);
      console.log("Itinerary item updated in Firestore");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
    handleCloseEditModal();
  };

  // Function to delete an itinerary item and show a snackbar
  const handleDeleteItineraryItem = async (itemId) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      setItinerary((prevItinerary) =>
        prevItinerary.filter((item) => item.id !== itemId)
      );
      try {
        const itemDocRef = doc(firestore, "Events", eventId, "Itinerary", itemId);
        await deleteDoc(itemDocRef);
        console.log("Itinerary item deleted from Firestore");
        setSnackbarMessage("Item successfully deleted.");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    }
  };

  // Handle closing of the Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={3}
      sx={{ padding: 3 }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" }
          }}
        >
          Agenda
        </Typography>
      </Grid>

      {/* (Optional) Toggle button for switching views if needed */}
      {owner && (
        <Grid item xs={12}>
          <Button
            onClick={() =>
              setViewMode((prevMode) =>
                prevMode === "cards" ? "list" : "cards"
              )
            }
          >
            {viewMode === "cards" ? "Switch to List View" : "Switch to Card View"}
          </Button>
        </Grid>
      )}

      {/* Render Itinerary Items in List View */}
      {viewMode === "list" && (
        <Grid container item spacing={2} justifyContent="center" xs={12}>
          {itinerary.map((item, index) => (
            <Grid
              item
              xs={12}
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 1,
                border: "1px solid #ccc",
                borderRadius: 2,
                textAlign: "left" // Left-align text
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
         
                <Box>
                  <Typography variant="h6">{item.vendor}</Typography>
                  <Typography variant="body2">
                    {convertTo12HourFormat(item.time)} {item.time && "on"} {item.date}
                  </Typography>
             
                </Box>
              </Box>
              {owner && (
                <Box>
                  {item.link && (
                    <>
                      <Button
                        onClick={() =>
                          window.open(item.link, "_blank", "noopener,noreferrer")
                        }
                        sx={{ mr: 1 }}
                        size="small"
                      >
                        Visit
                      </Button>
                      <Button
                        onClick={() => handleOpenEditModal(item)}
                        sx={{ mr: 1 }}
                        size="small"
                      >
                        Edit
                      </Button>
                    </>
                  )}
                  {!item.link && (
                    <Button
                      onClick={() => handleOpenEditModal(item)}
                      sx={{ mr: 1 }}
                      size="small"
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    className="delete"
                    onClick={() => handleDeleteItineraryItem(item.id)}
                    size="small"
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </Grid>
          ))}
          {owner && (
            <Grid item xs={12}>
              <Button onClick={handleOpenAddModal}>Add</Button>
            </Grid>
          )}
        </Grid>
      )}

      {/* (Optional) Card view rendering if needed */}
      {viewMode === "cards" && (
        <Grid container item spacing={3} justifyContent="center" xs={12}>
          {itinerary.map((item, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Box
                sx={{
                  borderRadius: "50%",
                  overflow: "hidden",
                  width: 250,
                  height: 250,
                  margin: "auto",
                }}
              >
                <RotatingCard sx={{ width: "100%", height: "100%" }}>
                  <RotatingCardFront
                    image={item.image}
                    icon="checklist"
                    description={item.vendor}
                  />
                  <RotatingCardBack
                    image={headerImage}
                    title={convertTo12HourFormat(item.time)}
                    description={item.date}
                    action={{
                      type: "button",
                      label: owner && item.link ? "Visit" : owner ? "Edit" : "",
                      onClick: () => {
                        if (owner) {
                          if (item.link) {
                            window.open(item.link, "_blank", "noopener,noreferrer");
                          } else {
                            handleOpenEditModal(item);
                          }
                        }
                      },
                    }}
                  />
                </RotatingCard>
              </Box>
              {owner && (
                <Box sx={{ mt: 1 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteItineraryItem(item.id)}
                    size="small"
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </Grid>
          ))}
          {owner && (
            <Grid item xs={12}>
              <Button onClick={handleOpenAddModal}>Add</Button>
            </Grid>
          )}
        </Grid>
      )}

      {/* Add Vendor Modal */}
      <Dialog open={openAddModal} onClose={handleCloseAddModal}>
        <DialogTitle>Add to Itinerary</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Vendor</InputLabel>
            <Select
              value={selectedVendor}
              onChange={(e) => setSelectedVendor(e.target.value)}
              label="Vendor"
              disabled={useCustomVendor}
            >
              {vendors.map((vendor) => (
                <MenuItem key={vendor.vendor_id} value={vendor.vendor_name}>
                  {vendor.vendor_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={useCustomVendor}
                onChange={(e) => setUseCustomVendor(e.target.checked)}
                color="primary"
              />
            }
            label="Use Custom"
          />
          {useCustomVendor && (
            <TextField
              label="Custom Item Name"
              value={customVendor}
              onChange={(e) => setCustomVendor(e.target.value)}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          )}
          <TextField
            label="Event Time (optional)"
            type="time"
            value={eventTime}
            onChange={(e) => setEventTime(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            inputProps={{
              step: 300, // 5 minutes interval
            }}
          />
          {/* Optional URL Input with inline Add/Edit buttons */}
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={8}>
              <TextField
                label="Optional URL"
                type="url"
                value={rawAddUrl}
                onChange={(e) => setRawAddUrl(e.target.value)}
                onBlur={() => {
                  if (
                    rawAddUrl &&
                    !rawAddUrl.startsWith("http://") &&
                    !rawAddUrl.startsWith("https://")
                  ) {
                    setRawAddUrl(`https://${rawAddUrl}`);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  readOnly: addLinkConfirmed,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {addLinkConfirmed ? (
                <Button
                  variant="outlined"
                  onClick={() => setAddLinkConfirmed(false)}
                  size="small"
                >
                  Edit Link
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (rawAddUrl.trim() !== "") {
                      if (
                        !rawAddUrl.startsWith("http://") &&
                        !rawAddUrl.startsWith("https://")
                      ) {
                        setRawAddUrl(`https://${rawAddUrl}`);
                      }
                      setAddLinkConfirmed(true);
                    } else {
                      alert("Please enter a valid URL or leave it blank.");
                    }
                  }}
                  size="small"
                >
                  Add Link
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddVendorToItinerary} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Vendor Modal */}
      <Dialog open={openEditModal} onClose={handleCloseEditModal}>
        <DialogTitle>Edit Itinerary Item</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Vendor</InputLabel>
            <Select
              value={editSelectedVendor}
              onChange={(e) => setEditSelectedVendor(e.target.value)}
              label="Vendor"
              disabled={editUseCustomVendor}
            >
              {vendors.map((vendor) => (
                <MenuItem key={vendor.vendor_id} value={vendor.vendor_name}>
                  {vendor.vendor_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={editUseCustomVendor}
                onChange={(e) => setEditUseCustomVendor(e.target.checked)}
                color="primary"
              />
            }
            label="Use Custom"
          />
          {editUseCustomVendor && (
            <TextField
              label="Custom Item Name"
              value={editCustomVendor}
              onChange={(e) => setEditCustomVendor(e.target.value)}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          )}
          <TextField
            label="Event Time (optional)"
            type="time"
            value={editEventTime}
            onChange={(e) => setEditEventTime(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            inputProps={{
              step: 300, // 5 minutes interval
            }}
          />
          {/* Optional URL Input with inline Add/Edit buttons in edit modal */}
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={8}>
              <TextField
                label="Optional URL"
                type="url"
                value={rawEditUrl}
                onChange={(e) => setRawEditUrl(e.target.value)}
                onBlur={() => {
                  if (
                    rawEditUrl &&
                    !rawEditUrl.startsWith("http://") &&
                    !rawEditUrl.startsWith("https://")
                  ) {
                    setRawEditUrl(`https://${rawEditUrl}`);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                  readOnly: editLinkConfirmed,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {editLinkConfirmed ? (
                <Button
                  variant="outlined"
                  onClick={() => setEditLinkConfirmed(false)}
                  size="small"
                >
                  Edit Link
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (rawEditUrl.trim() !== "") {
                      if (
                        !rawEditUrl.startsWith("http://") &&
                        !rawEditUrl.startsWith("https://")
                      ) {
                        setRawEditUrl(`https://${rawEditUrl}`);
                      }
                      setEditLinkConfirmed(true);
                    } else {
                      alert("Please enter a valid URL or leave it blank.");
                    }
                  }}
                  size="small"
                >
                  Add Link
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateItineraryItem} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for delete confirmation */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Grid>
  );
};

Itinerary.propTypes = {
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      vendor_id: PropTypes.string,
      vendor_name: PropTypes.string.isRequired,
      pictureURL: PropTypes.string,
    })
  ).isRequired,
  owner: PropTypes.bool, // Owner flag to show extra controls
  event_date: PropTypes.string, // Event date string
  eventId: PropTypes.string.isRequired, // Event ID for itinerary
};

export default Itinerary;
