import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import efLogo from "../assets/images/ef_logo_25.png";

// Vendor interface
interface Vendor {
  vendor_id: string;
  pictureURL: string;
  vendor_name: string;
  vendor_type: string;
  vendor_majorCity: string;
}

interface EFCardProps {
  posts: Vendor[];
  layout?: 'carousel' | 'grid';
  displayField?: 'city' | 'type';
}

const EFCard: React.FC<EFCardProps> = ({ posts, layout = 'grid', displayField = 'type' }) => {
  const navigate = useNavigate();

  const renderDescription = (vendor: Vendor): string => {
    return displayField === 'city' ? vendor.vendor_majorCity : vendor.vendor_type;
  };

  const renderCard = (vendor: Vendor, i: number) => (
    <Box
      key={vendor.vendor_id || i}
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        boxShadow: 3,
        backgroundColor: 'white',
      }}
    >
      <Box
        component="div"
        onClick={() => navigate(`/vendor/${vendor.vendor_id}`)}
        sx={{
          width: '100%',
          height: '200px',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          cursor: 'pointer',
          transition: 'transform 0.2s ease',
          '&:hover': {
            transform: 'scale(1.03)',
          },
        }}
      >
        <img
          src={vendor.pictureURL || efLogo}
          alt={vendor.vendor_name}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Box p={2}>
        <Typography variant="h6" fontWeight="bold">
          {vendor.vendor_name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {renderDescription(vendor)}
        </Typography>
      </Box>
    </Box>
  );

  if (layout === 'carousel') {
    return (
      <Box sx={{ display: 'flex', overflowX: 'auto', gap: 2 }}>
        {posts.map(renderCard)}
      </Box>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {posts.map((vendor, i) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={vendor.vendor_id || i}>
              {renderCard(vendor, i)}
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }
};

export default EFCard;
