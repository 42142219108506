import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { firestore } from "../../../../../firebase";

const EditItineraryItem = () => {
  const [vendor, setVendor] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [useCustomVendor, setUseCustomVendor] = useState(false);
  const [customVendor, setCustomVendor] = useState("");
  const [itemData, setItemData] = useState(null);
  const { eventId, itemId } = useParams();
  const navigate = useNavigate();
  const [vendors, setVendors] = useState([]);  // List of vendors

  useEffect(() => {
    const fetchData = async () => {
      // Clear previous state before loading new data
      setItemData(null);
      setVendor("");
      setEventTime("");
      setUseCustomVendor(false);
      setCustomVendor("");

      // Fetch the vendors
      const vendorsRef = collection(firestore, "Vendors");
      const vendorSnapshot = await getDocs(vendorsRef);
      const vendorsList = vendorSnapshot.docs.map(doc => doc.data());
      setVendors(vendorsList);

      // Fetch the itinerary item data
      const itineraryItemRef = doc(firestore, "Events", eventId, "Itinerary", itemId);
      const itineraryItemSnapshot = await getDoc(itineraryItemRef);
      const itineraryItemData = itineraryItemSnapshot.data();
      if (itineraryItemData) {
        setItemData(itineraryItemData);
        setVendor(itineraryItemData.vendor);
        setEventTime(itineraryItemData.time);
        setUseCustomVendor(itineraryItemData.vendor === "Custom");
        setCustomVendor(itineraryItemData.customVendor || "");
      }
    };

    fetchData();
  }, [eventId, itemId]);

  const handleSave = async () => {
    const updatedItem = {
      vendor: useCustomVendor ? customVendor : vendor,
      time: eventTime,
      customVendor: useCustomVendor ? customVendor : undefined,
    };

    const itemRef = doc(firestore, "Events", eventId, "Itinerary", itemId);
    await updateDoc(itemRef, updatedItem);
    navigate(`/events/${eventId}`);
  };

  if (!itemData) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{ padding: 3 }}>
      <Grid item xs={12}>
        <Dialog open={true}>
          <DialogTitle>Edit Itinerary Item</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Vendor</InputLabel>
              <Select
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
                label="Vendor"
                disabled={useCustomVendor}
              >
                {vendors.map((v) => (
                  <MenuItem key={v.vendor_id} value={v.vendor_name}>
                    {v.vendor_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={useCustomVendor}
                  onChange={(e) => setUseCustomVendor(e.target.checked)}
                />
              }
              label="Use Custom"
            />

            {useCustomVendor && (
              <TextField
                label="Custom Vendor Name"
                value={customVendor}
                onChange={(e) => setCustomVendor(e.target.value)}
                fullWidth
                sx={{ marginBottom: 2 }}
              />
            )}

            <TextField
              label="Event Time"
              type="time"
              value={eventTime}
              onChange={(e) => setEventTime(e.target.value)}
              fullWidth
              sx={{ marginBottom: 2 }}
              inputProps={{ step: 300 }} // 5 minutes
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => navigate(`/events/${eventId}`)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default EditItineraryItem;
