import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import defaultImage from '../../../../../assets/images/2025eventflowbg.png';

const ViewVendors = ({
  open,
  onClose,
  isOwner,
  event,
  eventId,
  vendorList,
  handleRemoveVendor
}) => {
  const navigate = useNavigate();

  const venueVendor = vendorList.find(v => v.vendor_type.toLowerCase() === 'venue');
  const otherVendors = vendorList.filter(v => v.vendor_type.toLowerCase() !== 'venue');

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Vendors</DialogTitle>
      <DialogContent>
        {event.public && (
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Where
          </Typography>
        )}

        {venueVendor && (
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" gap={2} mb={4}>
            <CardMedia
              component="img"
              image={venueVendor.pictureURL || defaultImage}
              alt={venueVendor.vendor_name}
              sx={{ width: 200, height: 200, borderRadius: 2, objectFit: 'cover' }}
            />
            <Box>
              <Typography variant="h6">Location</Typography>
              <Typography variant="body1">{venueVendor.vendor_name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {venueVendor.vendor_location}
              </Typography>
              <Box
  mt={2}
  display="flex"
  flexDirection={{ xs: 'column', sm: 'row' }}
  gap={1}
  alignItems={{ xs: 'stretch', sm: 'center' }}
>
  {isOwner ? (
    <>
      <Button
        variant="contained"
        className="ef-sm-btn inverted"
        onClick={() => navigate(`/vendor/${venueVendor.vendor_id}`)}
      >
        Visit
      </Button>
      <Button
        variant="outlined"
        className="ef-sm-btn"
        onClick={() => navigate(`/events/${eventId}/inventory/${venueVendor.id}`)}
      >
        View Offerings
      </Button>
      <Button
        color="error"
        className="delete"
        onClick={() => handleRemoveVendor(venueVendor.id)}
      >
        Remove
      </Button>
    </>
  ) : (
    <Button
      variant="contained"
      className="ef-sm-btn"
      onClick={() => navigate(`/vendor/${venueVendor.vendor_id}`)}
    >
      Visit
    </Button>
  )}
</Box>

            </Box>
          </Box>
        )}

        <Typography variant="h6" sx={{ mt: 2 }}>Vendors</Typography>
        <Grid container spacing={2} mt={1}>
          {otherVendors.length > 0 ? otherVendors.map((vendor) => (
            <Grid item xs={6} sm={4} md={3} key={vendor.id} textAlign="center">
              <Box onClick={() => navigate(`/vendor/${vendor.vendor_id}`)} sx={{ cursor: 'pointer' }}>
                <CardMedia
                  component="img"
                  image={vendor.pictureURL || defaultImage}
                  alt={vendor.vendor_name}
                  sx={{ width: '100%', height: 120, objectFit: 'cover', borderRadius: 2 }}
                />
                <Typography variant="body2" mt={1}>{vendor.vendor_name}</Typography>
              </Box>
            </Grid>
          )) : (
            <Typography>No additional vendors added yet.</Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewVendors.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  vendorList: PropTypes.array.isRequired,
  handleRemoveVendor: PropTypes.func.isRequired
};

export default ViewVendors;
