import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, Typography } from '@mui/material';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth, firestore } from '../firebase';

interface AddToEventProps {
  vendorId: string;
  vendorType: string;
  capacity?: number;  // Vendor's capacity
  className?: string; // Allow for custom styling
}

const EFAddToEvent: React.FC<AddToEventProps> = ({ vendorId, vendorType, capacity, className }) => {
  const [open, setOpen] = useState(false); // State to handle modal visibility
  const [events, setEvents] = useState<any[]>([]); // State to store fetched events
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch events when the modal opens
  const fetchEvents = async () => {
    try {
      setLoading(true);
      const eventsCollection = collection(firestore, 'Events');
      const q = query(eventsCollection, where("ownerId", "array-contains", auth.currentUser.uid));

      const querySnapshot = await getDocs(q);
      const eventsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEvents(eventsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching events: ", error);
      setLoading(false);
    }
  };

  // Check if vendor can be added to the event
  const handleAddToEvent = async (eventId: string, eventCapacity: number) => {
    // Check if the vendor's capacity is greater than the event's capacity
    if (capacity && eventCapacity > capacity) {
      setErrorMessage("Event capacity is larger than vendor's capacity. Please find a new venue or edit the capacity.");
      return;
    }

    try {
      const eventDocRef = doc(firestore, 'Events', eventId, 'Vendors', vendorId);
      await setDoc(eventDocRef, {
        vendor_id: vendorId,
        vendor_type: vendorType,
      }, { merge: true });

      setSuccessMessage('Vendor added to the event successfully!');
      setOpen(false); // Close the modal after adding
    } catch (error) {
      setErrorMessage(`Error adding vendor to event: ${error.message}`);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    fetchEvents();
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      {/* Add To Event Button with className for custom styling */}
      <button
        onClick={handleOpen}
        className={className || "ef-btn"} // Use className prop for custom styling, fallback to default
        style={{
          border: 'none',
          background: '#1e6c97',
          color: 'white',
          cursor: 'pointer',
        }}
      >
        Add To Event
      </button>

      {/* Event Selection Dialog */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Select an Event</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={2}>
              {events.length > 0 ? (
                events.map(event => (
                  <Grid item xs={12} sm={6} md={4} key={event.id}>
                     <div
    onClick={() => handleAddToEvent(event.id, event.event_capacity)} // Pass event capacity as parameter
    style={{
      cursor: 'pointer',
      border: '1px solid #ddd',
      padding: '16px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.2s ease',

    }}

  >
                      <img
                        src={event.event_image}
                        alt={event.event_name}
                        style={{
                          width: '100%',
                          height: 'auto',
                          borderRadius: '8px',
                          marginBottom: '8px',
                        }}
                      />
                      <Typography variant="h6" component="h3" gutterBottom>
                        {event.event_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {event.event_date}
                      </Typography>
                    </div>
                  </Grid>
                ))
              ) : (
                <Typography>No events available. Create one to add vendors.</Typography>
              )}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error and Success Snackbars */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={8000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={8000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
      <ToastContainer position="bottom-center" autoClose={8000} hideProgressBar />
    </>
  );
};

export default EFAddToEvent;
