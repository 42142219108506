import { Category, LocationCity, People } from '@mui/icons-material';
import { Box, CircularProgress, Grid, Snackbar, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import EFAddToEvent from '../../../components/EFAddToEvent';
import { auth, firestore } from '../../../firebase';
import Footer from "../../LandingPages/Author/sections/Footer";
import Profile from "../../Vendors/VendorPage/sections/Profile";
import BookingForm from '../Booking';
import SimilarVendorsCarousel from '../SimilarVendorsCarousel';
import ContactVendor from './sections/ContactVendor';
import InventoryPreview from './sections/InventoryPreview';
import KeyInfo from './sections/KeyInfo';
import Reviews from './sections/Reviews';

// Updated VendorPage Component
const VendorPage: React.FC = () => {
  let { id } = useParams();
  const [vendor, setVendor] = useState<any>();
  const [availableTimes, setAvailableTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const fetchVendor = async () => {
      const docRef = doc(firestore, 'vendors', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const vendorData = docSnap.data();
        setVendor(vendorData);
        setLoading(false);
        
        if (auth.currentUser && vendorData?.vendor_id !== auth.currentUser.uid) {
          try {
            const vendorRef = doc(firestore, 'vendors', id);
            await updateDoc(vendorRef, {
              vendor_viewCount: (vendorData.vendor_viewCount || 0) + 1,
            });
          } catch (error) {
            console.error('Error updating view count:', error);
          }
        }
      }
    };

    fetchVendor();
  }, [id]);

  const onBookingAccepted = (selectedDate, selectedTime) => {
    setAvailableTimes((prevAvailableTimes) =>
      prevAvailableTimes.filter(
        (timeObj) => timeObj.time !== selectedTime || !dayjs(timeObj.date).isSame(selectedDate, 'day')
      )
    );
  };

  const titleStyle = vendor?.vendor_name?.length > 25 ? { fontSize: '0.85em' } : {};
/* 
const handleAddToFavorites = async () => {
    try {
      const favoritesDocRef = doc(firestore, 'users', auth.currentUser?.uid, 'Favorites', id);
      await setDoc(favoritesDocRef, {
        vendor_id: vendor?.vendor_id,
        vendor_name: vendor?.vendor_name,
        vendor_type: vendor?.vendor_type,
        added_at: new Date(),
      }, { merge: true });

      setSuccessMessage('Vendor added to favorites successfully!');
    } catch (error) {
      setErrorMessage(`Error adding vendor to favorites: ${error.message}`);
    }
  }; */

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ backgroundImage: 'linear-gradient(#ff8a41, #fff)', backgroundSize: 'cover', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ padding: 1 }}>
    
    
      </Grid>
 

      <Box sx={{ backgroundColor: '#F2F2F2', boxShadow: 3, p: 3, mx: 'auto', width: { xs: '95%', sm: '80%', md: '85%' }, mt: 10 }}>
       
      {vendor.vendor_tier > 0 && (
            <>
            <Grid container pt={0} lg={8}>
           
<InventoryPreview id={id} tier={String(vendor.vendor_tier)} />
</Grid>
</>
            )}


<Grid container spacing={2} alignItems="center">
  {/* Image and text grid item */}
  <Grid item xs={12} sm={8} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
    {/* Image */}
    <Box
      sx={{
        width: { xs: '100%', sm: '250px' },  // Responsive width for mobile and larger screens
        height: { xs: 'auto', sm: '250px' },  // Ensures height adjusts on smaller screens
        objectFit: 'cover',
        marginRight: 2, // Margin for spacing
        cursor: 'zoom-in',
      }}
    >
      <img
        src={vendor.pictureURL}
        
        alt="vendor profile"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
    </Box>

    {/* Text Content */}
    <Box sx={{ flex: 1 }}>
  <Typography
    variant="h5"
    component="h2"
    sx={{
      //marginBottom: 1,
      whiteSpace: 'nowrap', // Prevent text wrapping
      overflow: 'hidden',   // Hide overflow (optional)
      textOverflow: 'ellipsis', // Show ellipsis if the text is too long
      fontSize: { xs: '1.2rem', sm: '1.5rem' }, // Make text smaller on mobile
    }}
  >
    {vendor?.vendor_name}
  </Typography>

  <Typography
    className="italic"
    sx={{
      //marginBottom: 1,
      whiteSpace: 'nowrap', // Prevent text wrapping
      overflow: 'hidden',   // Hide overflow
      textOverflow: 'ellipsis', // Show ellipsis for overflowed text
      fontSize: { xs: '1rem', sm: '1.2rem' }, // Make text smaller on mobile
    }}
  >
    <Category /> {vendor?.vendor_type}
  </Typography>

  {vendor?.vendor_type === "Venue" && (
    <Typography
      className="italic"
      sx={{
       // marginBottom: 1,
        whiteSpace: 'nowrap', // Prevent text wrapping
        overflow: 'hidden',   // Hide overflow
        textOverflow: 'ellipsis', // Show ellipsis if the text is too long
        fontSize: { xs: '1rem', sm: '1.2rem' }, // Make text smaller on mobile
      }}
    >
      <People /> Capacity: {vendor?.vendor_capacity}
    </Typography>
  )}

  <Typography
    className="italic"
    sx={{
      marginBottom: 2,
      whiteSpace: 'nowrap', // Prevent text wrapping
      overflow: 'hidden',   // Hide overflow
      textOverflow: 'ellipsis', // Show ellipsis for overflowed text
      fontSize: { xs: '1rem', sm: '1.2rem' }, // Make text smaller on mobile
    }}
  >
    <LocationCity /> Location: {vendor?.vendor_city}, {vendor?.vendor_state}
  </Typography>

  {/* Action Button */}
  {auth != null && (
    <EFAddToEvent
      vendorId={id}
      capacity={Number(vendor?.vendor_capacity)}
      vendorType={vendor?.vendor_type}
      className="ef-cta-btn"
    />
  )}
</Box>

  </Grid>
</Grid>



        <Grid container pt={0} lg={12}>
          <Profile
            id={id}
            avi={vendor?.pictureURL}
            name={vendor?.vendor_name}
            thisRating={vendor?.vendor_rating}
            thisType={vendor?.vendor_type}
            thisDescription={vendor?.vendor_description}
            vendor_own={vendor?.vendor_own}
            video={vendor?.vendor_video}
            email={vendor?.vendor_email}
            tier={vendor?.vendor_tier}
            capacity={vendor?.vendor_capacity}
          />

{vendor?.vendor_tier === 2 && 
              <BookingForm vendorId={id} vendorEmail={vendor?.vendor_email} onBookingAccepted={onBookingAccepted} vendorName={vendor?.vendor_name} />
              }

          {vendor?.vendor_tier > 0 && (
            <>
                          {/* view offerings from event page {vendor?.vendor_tier > 1 && <Pricing id={id} />} */}
                          <KeyInfo 
  id={id} 
  addOns={vendor?.vendor_addOns && vendor?.vendor_addOns.length > 0 ? vendor?.vendor_addOns : [null]} 
  amenities={vendor?.vendor_amenities && vendor?.vendor_amenities.length > 0 ? vendor?.vendor_amenities : ['No Amenities Available']} 
/>
          
             

              <ContactVendor id={id} address={vendor?.vendor_location} phone={vendor?.vendor_phone} name={vendor?.vendor_name} web={vendor?.vendor_website} />
            </>
          )}

          <Reviews id={id} />
        </Grid>
        {/* <SimilarVendorsCarousel vendorType={vendor.vendor_type} city={vendor.vendor_majorCity} /> */}
        <SimilarVendorsCarousel vendorType={vendor?.vendor_type} city={vendor?.vendor_majorCity} />
        <Footer />
      </Box>

      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')} message={successMessage} />
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')} message={errorMessage} />
    </div>
  );
};

export default VendorPage;
