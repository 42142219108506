import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { browserLocalPersistence, onAuthStateChanged, setPersistence } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import Cookies from 'js-cookie';
import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import theme from "./assets/theme";
import './ef-styles.css';

import DefaultNavbar from "./examples/Navbars/DefaultNavbar";
import { auth, firestore, logout } from "./firebase";

import Presentation from "./layouts/pages/presentation";
import ThreadDetail from "./pages/Community/Forum/components/ThreadDetail";
import AboutUs from "./pages/LandingPages/AboutUs";
import LogOut from "./pages/logout";
import CartPage from "./pages/Users/Cart";
import { CartProvider } from "./pages/Users/Cart/CartContext";
import CheckInPage from "./pages/Users/Events/CheckIn";
import EventDashboard from "./pages/Users/Events/Dashboard";
import EventDetails from "./pages/Users/Events/EventDetails";
import EditItineraryItem from "./pages/Users/Events/EventDetails/components/EditItineraryItem";
import RegisterUser from "./pages/Users/Register";
import UserSignIn from "./pages/Users/SignIn";
import Vendor from "./pages/Vendors";
import BookingManagement from "./pages/Vendors/Booking/Management";
import AdminDashboard from "./pages/Vendors/Dashboard";
import InventoryPage from "./pages/Vendors/Inventory";
import AddInventoryPage from "./pages/Vendors/Inventory/AddInventory";
import VendorSettings from "./pages/Vendors/Settings";
import VendorPage from "./pages/Vendors/VendorPage";
import { routes, vendorRoutes } from "./routes";

export default function App() {
  const { pathname } = useLocation();
  const [status, setStatus] = useState(false);
  const [globalDownloadURL, setglobalDownloadURL] = useState('');
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(false);

  // Cookie consent check
  useEffect(() => {
    const consent = Cookies.get("userCookieConsent");
    if (consent) {
      setCookieConsent(true);
    }
  }, []);

  // Firebase auth + persistence + user cookie logic
  useEffect(() => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            setStatus(true);

            try {
              const userDocRef = doc(firestore, 'users', user.uid);
              const userDoc = await getDoc(userDocRef);

              if (userDoc.exists()) {
                const userData = userDoc.data();
                const isVendorFlag = userData.isVendor === 1;

                // Save to cookie
                Cookies.set('user', JSON.stringify({
                  uid: user.uid,
                  email: user.email || null,
                  isVendor: isVendorFlag
                }), { expires: 7 });

                setIsVendor(isVendorFlag);
              } else {
                console.warn("User document not found.");
              }
            } catch (error) {
              console.error("Error fetching user data:", error);
            }
          } else {
            setStatus(false);
            setIsVendor(false);
            Cookies.remove('user');
          }
        });

        return () => unsubscribe(); // Cleanup
      })
      .catch((error) => {
        console.error("Error setting persistence: ", error);
      });
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  const handleLogout = () => {
    logout();
    setRedirectToHome(true);
  };

  if (redirectToHome) {
    return <Navigate to="/" />;
  }

  return (
    <CartProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {status ? (
          isVendor ? (
            <DefaultNavbar
              className="navbar"
              routes={vendorRoutes}
              action={{
                type: "internal",
                route: "/log-out",
                label: "Log Out",
                color: "accent",
              }}
              dark
            />
          ) : (
            <DefaultNavbar
              routes={routes}
              action={{
                type: "internal",
                route: "/log-out",
                label: "Log Out",
                color: "accent",
              }}
              dark
            />
          )
        ) : (
          <DefaultNavbar
            routes={routes}
            action={{
              type: "internal",
              route: "/sign-in",
              label: "Sign In",
              color: "accent",
            }}
            dark
          />
        )}

        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <div style={{ flex: 1 }}>
            <Routes>
              {getRoutes(routes)}
              <Route path="/" element={<Presentation />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/sign-in" element={<UserSignIn />} />
              <Route path="/welcometoeventflow" element={<RegisterUser />} />
              <Route path="/log-out" element={<LogOut />} />
              <Route path="/hi" element={<AboutUs />} />
              <Route path="/vendor/:id" element={<VendorPage />} />
              <Route path="/vendors/:city" element={<Vendor />} />
              <Route path="/events/:eventId" element={<EventDetails />} />
              <Route path="/vendor/:vendorId/inventory" element={<InventoryPage />} />
              <Route path="/events/:eventId/inventory/:vendorId" element={<InventoryPage />} />
              <Route path="/vendor/vendorsettings" element={<VendorSettings />} />
              <Route path="/vendoroutreach" element={<AboutUs />} />
              <Route path="/wicksandwine" element={<EventDetails eventId="n60A5RSArcz64DSoKcax" />} />
              <Route path="/threads/:threadId" element={<ThreadDetail />} />
              <Route path="/edititinerary/:eventId/:itemId" element={<EditItineraryItem />} />
              <Route path="/events/:eventId/check-in" element={<CheckInWrapper />} />
              <Route path="/vendor/dashboard" element={<AdminDashboard />} />
              <Route path="/events/:eventId/event-dashboard" element={<EventDashboardWrapper />} />
              <Route path="/vendor/:vendorId/add-inventory" element={<AddInventoryPage />} />
              <Route path="/cart/:eventId" element={<CartPage />} />
              <Route path="/managebookings" element={<BookingManagement />} />
            </Routes>
          </div>
        </div>

        {!cookieConsent && (
          <CookieConsent
            location="bottom"
            buttonText="Got it!"
            cookieName="userCookieConsent"
            style={{
              background: "#2B373B",
              color: "#fff",
              textAlign: "center",
              fontSize: "14px",
              padding: "10px",
            }}
            buttonStyle={{
              background: "#4e8c28",
              color: "#fff",
              borderRadius: "5px",
              padding: "10px 20px",
              fontSize: "14px",
            }}
            expires={365}
          >
            This website uses cookies to enhance the user experience. By continuing, you agree to our cookie policy.
          </CookieConsent>
        )}
      </ThemeProvider>
    </CartProvider>
  );
}

const CheckInWrapper = () => {
  const { eventId } = useParams();
  return <CheckInPage eventId={eventId} />;
};

const EventDashboardWrapper = () => {
  const { eventId } = useParams();
  return <EventDashboard eventId={eventId} />;
};
