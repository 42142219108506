import { format } from "date-fns";
import PropTypes from "prop-types";

const ExpediaAffiliateButton = ({ eventDate, eventCity, eventImage }) => {
  if (!eventDate || !eventCity) {
    //console.error("Event date or city is missing!");
    return null;
  }

  const startDate = format(new Date(eventDate), "yyyy-MM-dd");
  const endDate = format(
    new Date(new Date(eventDate).getTime() + 2 * 24 * 60 * 60 * 1000),
    "yyyy-MM-dd"
  );

  const encodedCity = encodeURIComponent(eventCity);

  const affiliateLink = `https://expedia.com/affiliate?siteid=1&landingPage=https%3A%2F%2Fwww.expedia.com%2FHotel-Search%3Fdestination%3D${encodedCity}%252C%2BUnited%2BStates%26flexibility%3D0_DAY%26d1%3D${startDate}%26startDate%3D${startDate}%26d2%3D${endDate}%26endDate%3D${endDate}%26adults%3D2%26rooms%3D1%26isInvalidatedDate%3Dfalse%26theme%3D%26userIntent%3D%26semdtl%3D%26useRewards%3Dfalse%26sort%3DRECOMMENDED%26siteid%3D1%26langid%3D1033&camref=1011l4hPmq&creativeref=1100l68075&adref=PZ8ac0K8n8`;

  return (
    <div className="flex items-center justify-center">
      <a
        href={affiliateLink}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition-colors"
      >
        <img
        width={100}
        height={100}
          src={eventImage}
          alt="Expedia Logo"
          className="h-6 w-6 mr-2"
        />
        Find Hotels on Expedia
      </a>
    </div>
  );
};

ExpediaAffiliateButton.propTypes = {
  eventDate: PropTypes.string.isRequired,
  eventCity: PropTypes.string.isRequired,
  eventImage: PropTypes.string,
};

export default ExpediaAffiliateButton;
