import { addDoc, collection, deleteDoc, getDocs, query, where } from 'firebase/firestore';
import PropTypes from 'prop-types'; // PropTypes for validation
import { createContext, useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../../../firebase'; // Firebase setup

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [eventsCart, setEventsCart] = useState({});
  const [currentEventId, setCurrentEventId] = useState(null); // To track the current eventId
  const user = auth.currentUser;

  // Fetch the cart data for the specific eventId
  const fetchCart = async (eventId) => {
    if (!user || !eventId) return;

    try {
      setLoading(true); // Set loading state to true while fetching data
      const cartRef = collection(firestore, 'users', user.uid, 'events', eventId, 'cart');
      const cartSnapshot = await getDocs(cartRef);
      const cartItems = cartSnapshot.docs.map((doc) => doc.data());

      // Update the cart for the specific eventId
      setEventsCart((prevState) => ({
        ...prevState,
        [eventId]: cartItems,
      }));
    } catch (error) {
      console.error('Error fetching cart:', error);
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  // Add item to cart in Firebase for a specific event
  const addToCart = async (eventId, item) => {
    if (!user || !eventId) return;

    const cartItem = {
      id: item.id,
      name: item.name,
      price: item.price,
      imageUrl: item.imageUrl || '',
      description: item.description || '',
    };

    try {
      const cartRef = collection(firestore, 'users', user.uid, 'events', eventId, 'cart');
      await addDoc(cartRef, cartItem);
      fetchCart(eventId); // Fetch updated cart after adding item
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  // Remove item from cart in Firebase
  const removeFromCart = async (eventId, itemId) => {
    if (!user || !eventId) return;
  
    try {
      // Reference to the cart collection for the current user and event
      const cartRef = collection(firestore, 'users', user.uid, 'events', eventId, 'cart');
      
      // Query to find the specific item in the cart by itemId
      const q = query(cartRef, where('id', '==', itemId));
      const cartItemSnapshot = await getDocs(q);
  
      // If item exists, delete it
      cartItemSnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref); // Delete the document from Firestore
      });
  
      // Re-fetch the cart to update the UI for the specific event
      fetchCart(eventId); // Fetch updated cart after deletion
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };
    

  // Get the total price of all items in the cart
  const getTotalPrice = () => {
    let total = 0;

    for (const eventId in eventsCart) {
      if (Object.prototype.hasOwnProperty.call(eventsCart, eventId)) {
        const cartItems = eventsCart[eventId];
        total += cartItems.reduce((sum, item) => sum + item.price * (item.quantity || 1), 0);
      }
    }

    return total;
  };

  // Use useEffect to fetch cart when user or eventId changes
  useEffect(() => {
    if (user && currentEventId) {
      fetchCart(currentEventId); // Fetch cart data on component mount and when eventId changes
    }
  }, [user, currentEventId]);

  return (
    <CartContext.Provider value={{
      eventsCart,
      addToCart,
      removeFromCart,
      getTotalPrice,
      loading,
      setCurrentEventId, // Expose setter for eventId
    }}>
      {children}
    </CartContext.Provider>
  );
};

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CartProvider;
