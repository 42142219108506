import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import React, { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import efLogo from '../assets/images/ef_logo_25.png';
import { auth } from '../firebase';
interface UploadProps {
    vendorId?: string;
    id?: string; // ID of the user, vendor, inventory item, or review
    source: 'users' | 'vendors' | 'Inventory' | 'Reviews' | 'Events' | 'Images'; // Source of the photo upload
    onUploadComplete: (url: string) => void; // Callback to inform parent component
    initialImageURL?: string; // Optional prop for the initial image URL
}

const UploadPhoto: FC<UploadProps> = ({ vendorId, id, source, onUploadComplete, initialImageURL }) => {
    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [downloadURL, setDownloadURL] = useState<string | null>(initialImageURL || null); // Initialize with passed URL or null
    const [imagePreview, setImagePreview] = useState<string | null>(null); // For preview image
    const [uploadSuccess, setUploadSuccess] = useState(false); // Flag to track upload success

    const storage = getStorage();
    const db = getFirestore();

    // Handle file selection
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFile = e.target.files[0];
            setFile(selectedFile);

            // Generate a preview URL for the selected file
            const previewURL = URL.createObjectURL(selectedFile);
            setImagePreview(previewURL); // Set the preview URL
            setDownloadURL(null); // Reset download URL
            setUploadSuccess(false); // Reset upload success state
        }
    };

    // Handle photo upload to Firebase Storage
    const handleUpload = async () => {
        if (!file) return;

        const user = auth.currentUser;
        if (!user) {
            console.error('User is not authenticated');
            return;
        }

        // Determine the file path based on source (events, users, vendors, inventory, or review)
        let filePath = '';
        if (source === 'Events') {
            filePath = `users/${user.uid}/Events/${id}/photos/${uuidv4()}_${file.name}`;
          //  console.log('events photo upload filepath ', id);
        } else if (source === 'Images' || source === 'Inventory') {
            filePath = `vendors/${vendorId}/${source}/${uuidv4()}_${file.name}`;
        } else if (source === 'vendors') {
            filePath = `vendors/${vendorId}/${uuidv4()}_${file.name}`;
           // console.log('vendor photo found');
        } else {
            filePath = `users/${auth.currentUser.uid}/${uuidv4()}_${file.name}`;
        }

        const fileRef = ref(storage, filePath);

        try {
            setUploading(true);

            // Upload the file to Firebase Storage
            await uploadBytes(fileRef, file);

            // Get the download URL of the uploaded file
            const url = await getDownloadURL(fileRef);

            setDownloadURL(url); // Set the download URL state
            onUploadComplete(url); // Notify parent component with the URL
            setUploadSuccess(true); // Mark upload as successful

            // Store the URL in Firestore (under the appropriate path based on source)
            let docRef;

            if (source === 'Events') {
                docRef = doc(db, `users/${user.uid}/Events/${id}`);
            } else if (source === 'Images') {
                docRef = doc(db, `vendors/${vendorId}/${source}/${id}`);
              //  console.log('Image added to /Imagaes');
            } else if (source === 'vendors') {
                docRef = doc(db, `vendors/${vendorId}`);
               // console.log('vendor photo set');

            } else {
                docRef = doc(db, `users/${auth.currentUser.uid}/`);
            }

            // Merge the photo URL into the document (don't overwrite other fields)
            if (source === 'vendors') {
                await setDoc(docRef, { pictureURL: url }, { merge: true });

            } else{
                await setDoc(docRef, { pictureURL: url }, { merge: true });

            }
            //console.log('events photo upload source ', id);

        } catch (error) {
            console.error('Error uploading file: ', error);
        } finally {
            setUploading(false);
        }
    };

    return (
        <div>
            {/* Hidden file input, triggered by the image placeholder */}
            <input
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}  // Hide the file input element
                id="upload-photo-input"  // Set ID to trigger from the label
            />

            {/* If no image is uploaded, show the placeholder image as a button */}
            {!imagePreview && !downloadURL && (
                <label htmlFor="upload-photo-input" style={{ cursor: 'pointer' }}>
                    <img
                        src={efLogo}// Placeholder image URL
                        alt="Placeholder"
                        style={{
                            width: '150px',
                            height: '150px',
                            objectFit: 'cover',
                            borderRadius: '8px',
                            marginTop: '10px',
                            backgroundColor: '#000',
                        }}
                    />
                    <p style={{ textAlign: 'center', color: '#888', marginTop: '5px' }}>
                        Click to upload photo
                    </p>
                </label>
            )}

            {/* Show the preview image after selecting a file */}
            {imagePreview && (
                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <img
                        src={imagePreview} // Preview of the selected image
                        alt="Selected Preview"
                        style={{
                            width: '150px',
                            height: '150px',
                            objectFit: 'cover',
                            borderRadius: '8px',
                            marginTop: '10px',
                        }}
                    />
                    <p style={{ color: '#888' }}>Preview</p>
                    <br />
                    Please click &lsquo;Upload Photo&lsquo; after selecting before clicking &lsquo;Next&lsquo; to complete upload!
<br></br>
                    <button
                        onClick={() => setImagePreview(null)}  // Reset preview when replacing
                        style={{
                            marginTop: '10px',
                            padding: '5px 10px',
                            backgroundColor: '#FF4C4C',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        Replace Image
                        <br>
                        </br>
                    </button>
                </div>
            )}

            {/* Show the uploaded image if available */}
            {downloadURL && !imagePreview && (
                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <img src={downloadURL} alt="Uploaded" style={{ maxWidth: '300px', marginTop: '10px' }} />
                </div>
            )}

            {/* Upload button */}
            {file && !uploading && !uploadSuccess && (
                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <button
                        onClick={handleUpload}
                        disabled={uploading}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: '#007BFF',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        {uploading ? 'Uploading...' : 'Upload Photo'}
                    </button>
                </div>
            )}

            {uploadSuccess && (
                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <button
                        disabled
                        style={{
                            padding: '10px 20px',
                            backgroundColor: '#28a745', // Green for success
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        Photo Uploaded!
                    </button>
                </div>
            )}

            {/* If uploading, show progress */}
            {uploading && <p style={{ textAlign: 'center' }}>Uploading...</p>}
        </div>
    );
};

export default UploadPhoto;
