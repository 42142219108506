/*
Usage:       <EFQRCode eventId={eventId} />

*/

import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import PropTypes from "prop-types";
import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { firestore } from "../../../../../firebase";

const EFQRCode = ({ eventId }) => {
  const [qrCode, setQrCode] = useState(null);
  const [checkInFormVisible, setCheckInFormVisible] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "" });

  // Generate and save QR code in Firestore
  const generateQRCode = async () => {
   const eventCheckInUrl = `https://eventflow.app/events/${eventId}/check-in`;
   // const eventCheckInUrl = `https://eventflow.app/`;
   //const eventCheckInUrl = `https://eventflow.app/wicksandwine`;

    try {
      const qrCodeUrl = await QRCode.toDataURL(eventCheckInUrl);

      const eventDocRef = doc(firestore, "Events", eventId);
      await updateDoc(eventDocRef, { qr_code: qrCodeUrl });

      setQrCode(qrCodeUrl);
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  // Fetch QR code from Firestore
  const fetchQRCode = async () => {
    try {
      const eventDocRef = doc(firestore, "Events", eventId);
      const eventSnapshot = await getDoc(eventDocRef);

      if (eventSnapshot.exists()) {
        const data = eventSnapshot.data();
        if (data.qr_code) {
          setQrCode(data.qr_code);
        } else {
          await generateQRCode(); // Generate if not already saved
        }
      } else {
        console.error("Event not found");
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  // Handle check-in form submission
  const handleCheckIn = async (e) => {
    e.preventDefault();

    try {
      const attendeesCollection = collection(firestore, "Events", eventId, "Attendees");
      await addDoc(attendeesCollection, {
        name: formData.name,
        email: formData.email,
        checkedInAt: new Date(),
      });

      alert("Check-in successful!");
      setFormData({ name: "", email: "" });
    } catch (error) {
      console.error("Error checking in user:", error);
    }
  };

  useEffect(() => {
    fetchQRCode();
  }, [eventId]);

  return (
    <div>
      <h5>Event QR Code</h5>
      {qrCode ? (
        <img src={qrCode} alt="Event QR Code" style={{ width: 200, height: 200 }} />
      ) : (
        <p>Loading QR Code...</p>
      )}

      {checkInFormVisible && (
        <form onSubmit={handleCheckIn} style={{ marginTop: "20px" }}>
          <input
            type="text"
            placeholder="Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
          />
          <button type="submit">Check In</button>
        </form>
      )}
    </div>
  );
};

EFQRCode.propTypes = {
  eventId: PropTypes.string.isRequired,
};


export default EFQRCode;
