// Merged and updated VendorRegistration.js with full original logic, password confirmation, and progress bar
import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from '@mui/material/TextField';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import bgImage from "../../../../assets/images/bg1.jpg";
import EFTiers from '../../../../components/EFTiers';
import MKBox from "../../../../components/MKBox";
import MKButton from "../../../../components/MKButton";
import MKInput from "../../../../components/MKInput";
import { businessTypeDropdown, city_names, makeid, states } from "../../../../ef-constants";
import { auth, firestore } from '../../../../firebase';
import UploadPhoto from "../../../../functions/UploadPhoto";

function VendorRegistration() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [tier, setTier] = useState(0);
  const [docId, setDocID] = useState('');
  const [capacity, setCapacity] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [tags, setTags] = useState('');
  const [vendorType, setType] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  const [majorCity, setMajorCity] = useState('');
  const [picture, setPicture] = useState('');
  const [number, setNumber] = useState('');
  const [hours, setHours] = useState('');
  const [generatedID, setGeneratedID] = useState('');

  const navigate = useNavigate();

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const getProgress = () => {
    return (step / 4) * 100;
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "name") setName(value);
    if (id === "email") setEmail(value);
    if (id === "password") setPassword(value);
    if (id === "confirmPassword") setConfirmPassword(value);
    if (id === "description") setDescription(value);
    if (id === "website") setWebsite(value);
    if (id === "city") setCity(value);
    if (id === "state") setState(value);
    if (id === "zip") setZip(value);
    if (id === "address") setAddress(value);
    if (id === "phone") setPhone(value);
    if (id === "number") setNumber(value);
    if (id === "majorCity") setMajorCity(value);
    if (id === "capacity") setCapacity(value);
  };

  const handleTierSelect = (tierName) => {
    switch (tierName) {
      case 'Free': setTier(0); break;
      case 'Lite': setTier(1); break;
      case 'Standard': setTier(2); break;
      default: setTier(0);
    }
  };

  const handleCreateUser = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      return userCredential.user;
    } catch (error) {
      setError(error.message);
      console.error('Error creating user:', error.message);
      return null;
    }
  };

  const createUserData = async (user) => {
    try {
      await setDoc(doc(firestore, 'users', user.uid), {
        email: user.email,
        id: user.uid,
        createdAt: new Date(),
        isVendor: 1,
      });
      nextStep();
    } catch (err) {
      console.error('Error creating user data:', err);
    }
  };

  const handleUploadComplete = (url) => {
    setPicture(url);
  };

  const handleSave = async () => {
    if (step === 1) {
      if (!email || !password || !confirmPassword) {
        alert('Please fill in all required fields.');
        return;
      }
      if (password !== confirmPassword) {
        alert('Passwords do not match.');
        return;
      }
      const user = await handleCreateUser(email, password);
      if (user) await createUserData(user);
    } else if (step === 2) {
      try {
        const vendorCollection = collection(firestore, 'vendors');
        const docRef = doc(vendorCollection, auth.currentUser.uid);
        const vendorData = {
          vendor_id: docRef.id,
          vendor_name: name,
          vendor_description: description,
          vendor_email: email,
          vendor_type: vendorType,
          vendor_capacity: capacity,
          vendor_city: city,
          vendor_state: state,
          vendor_zip: zip,
          vendor_location: address,
          vendor_number: number,
          vendor_availableTimes: ['2024-08-12T10:00:00', '2024-08-12T12:00:00'],
          vendor_hours: hours,
          vendor_tags: tags,
          vendor_website: website,
          pictureURL: picture,
          vendor_majorCity: majorCity,
          vendor_viewCount: 0,
          vendor_tier: tier,
          vendor_phone: phone,
          vendor_own: auth.currentUser?.uid,
          vendor_created: Date.now(),
          vendor_createdBy: auth.currentUser?.uid,
        };
        await setDoc(docRef, vendorData);
        setDocID(docRef.id);
        nextStep();
      } catch (e) {
        alert('Error saving vendor data. Please try again.');
        setError(e.message);
        console.error('Error saving vendor data:', e.message);
      }
    } else {
      nextStep();
    }
    if (step === 4) navigate(`/vendor/${auth.currentUser.uid}`);
  };

  useEffect(() => {
    if (step === 1 && auth.currentUser) {
      alert('You are already logged in, you cannot create a vendor.');
      navigate(-1);
    } else {
      setGeneratedID(makeid(35));
    }
  }, [docId, navigate]);

  return (
    <>
      <MKBox position="fixed" top={0} left={0} zIndex={1} width="100%" minHeight="100vh" sx={{ backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) => `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${bgImage})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundAttachment: "fixed" }} />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={10} lg={10} xl={10} mt={15} mb={10}>
            <Card>
              <MKBox variant="gradient" borderRadius="lg" coloredShadow="info" mx={2} mt={-3} p={2} mb={1} textAlign="center">
                <Typography variant="h4" fontWeight="medium" color="white" mt={1}>Eventflow Vendor Registration</Typography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox mb={3}><LinearProgress variant="determinate" value={getProgress()} /></MKBox>
                {/* All form steps rendered fully below without scroll constraint */}
                {step === 1 && (
                  <>
                    <MKBox mb={2}><MKInput type="email" id="email" required label="Email" onChange={handleInputChange} fullWidth /></MKBox>
                    <MKBox mb={2}><MKInput type="password" id="password" required label="Password" onChange={handleInputChange} fullWidth /></MKBox>
                    <MKBox mb={2}><MKInput type="password" id="confirmPassword" required label="Confirm Password" value={confirmPassword} onChange={handleInputChange} error={confirmPassword && password !== confirmPassword} helperText={confirmPassword && password !== confirmPassword ? "Passwords do not match" : ""} fullWidth /></MKBox>
                  </>
                )}

                {step === 2 && (
                  <>
                    <MKBox mb={2}><MKInput id="name" required label="Business Name" onChange={handleInputChange} fullWidth /></MKBox>
                    <MKBox mb={2}><MKInput id="address" label="Address" onChange={handleInputChange} fullWidth /></MKBox>
                    <MKBox mb={2}><MKInput id="city" label="City" required onChange={handleInputChange} fullWidth /></MKBox>
                    <MKBox mb={2}><InputLabel>State</InputLabel><Select labelId="State" id="state" value={state} onChange={handleStateChange} fullWidth>{states.map((x) => (<MenuItem key={x} value={x}>{x}</MenuItem>))}</Select></MKBox>
                    <MKBox mb={2}><MKInput id="zip" label="Zip Code" required onChange={handleInputChange} fullWidth /></MKBox>
                    <MKBox mb={2}><Autocomplete options={city_names} onChange={(event, value) => setMajorCity(value)} required renderInput={(params) => <TextField {...params} label="Select Major City" />} /></MKBox>
                    <MKBox mb={2}><MKInput id="description" multiline rows={5} label="Description" onChange={handleInputChange} fullWidth /></MKBox>
                    <MKBox mb={2}><InputLabel>Business Type</InputLabel><Select labelId="vendorType" id="vendorType" value={vendorType} onChange={handleTypeChange} fullWidth>{businessTypeDropdown.map((type) => (<MenuItem key={type} value={type}>{type}</MenuItem>))}</Select></MKBox>
                    <MKBox mb={2}><MKInput id="phone" required label="Phone Number" onChange={handleInputChange} fullWidth /></MKBox>
                    <MKBox mb={2}><MKInput id="website" label="Website" onChange={handleInputChange} fullWidth /></MKBox>
                    {(vendorType === 'Transportation' || vendorType === 'Venue') && (<MKBox mb={2}><MKInput id="capacity" label="Capacity" value={capacity} onChange={handleInputChange} fullWidth /></MKBox>)}
                  </>
                )}

                {step === 3 && docId && (
                  <>
                    <h2>Upload Photo</h2>
                    <UploadPhoto vendorId={auth.currentUser?.uid} source="vendors" onUploadComplete={(url) => { setPicture(url); }} />
                  </>
                )}

                {step === 4 && (<EFTiers onSelectTier={handleTierSelect} />)}

                {error && <div>{error}</div>}

                <MKBox display="flex" justifyContent="space-between" mt={3}>
                  {step > 1 && (<MKButton variant="outlined" color="info" fullWidth onClick={() => setStep((prev) => prev - 1)}>Previous</MKButton>)}
                  {step < 4 && (<MKButton variant="gradient" color="info" className='ef-btn' fullWidth onClick={handleSave}>Next</MKButton>)}
                  {step === 4 && (<MKButton variant="gradient" color="info" fullWidth className='ef-btn' onClick={handleSave}>Sign Up</MKButton>)}
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default VendorRegistration;
