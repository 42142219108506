import * as firebase from 'firebase/app';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import 'firebase/firestore';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import 'firebase/storage';
import { getStorage, ref } from 'firebase/storage';
import Cookies from 'js-cookie';

// Firebase Config
const firebaseConfig = {
  apiKey: "AIzaSyAn-Upj0XlPC51K7M6MnQxO4k4Lsi9DFH4",
  authDomain: "eventflow-dev-1ffc9.firebaseapp.com",
  projectId: "eventflow-dev-1ffc9",
  storageBucket: "eventflow-dev-1ffc9.appspot.com",
  messagingSenderId: "220263589159",
  appId: "1:220263589159:web:1b747c6347ea4e96ae3502",
  measurementId: "G-ZEV40LW70Q",
};

const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage();
export const storageRef = ref(storage);
export const functions = getFunctions(app);

// Store user info in cookies
const storeUserInCookies = (user) => {
  Cookies.set('user', JSON.stringify({
    uid: user.uid,
    email: user.email || null,
    isVendor: user.isVendor,
  }), { expires: 7 });
};

// Retrieve user info from cookies
const getUserFromCookies = () => {
  const userCookie = Cookies.get('user');
  return userCookie ? JSON.parse(userCookie) : null;
};

export const cookiesUser = getUserFromCookies();

// Sign-In with email and password
export const handleSignIn = async (email, password, rememberMe) => {
  try {
    const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
    await setPersistence(auth, persistence);

    // Sign in user
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Get user data from Firestore
    const userDocRef = doc(firestore, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const isVendor = userData.isVendor === 1; // Check if user is a vendor

      // Add `isVendor` to the current user object
      const enrichedUser = { ...user, isVendor };

      // Store user in cookies
      storeUserInCookies(enrichedUser);

      // Redirect based on user type
      if (isVendor) {
        window.location.href = '/vendor/dashboard'; // Vendor route
      } else {
        window.location.href = '/events'; // Non-vendor route
      }
    } else {
      console.error('User document not found in Firestore.');
    }
  } catch (error) {
    console.error('Error signing in:', error);
    alert('Error signing in: ' + error.message);
  }
};

// Anonymous Sign-In
export const anonSignIn = async () => {
  try {
    const userCredential = await signInAnonymously(auth);
    const user = userCredential.user;

    storeUserInCookies(user);
    console.log('Anonymous user signed in.');
  } catch (error) {
    console.error('Error signing in anonymously:', error);
  }
};

// Sign-Out
export const logout = () => {
  signOut(auth)
    .then(() => {
      Cookies.remove('user');
      window.location.href = '/';
    })
    .catch((error) => {
      console.error('Error signing out:', error);
    });
};

// Check User Session
export const checkUserSession = () => {
  const user = getUserFromCookies();
  if (user) {
    console.log('User session found:', user);
    return user;
  }
  console.log('No user session found.');
  return null;
};

// Use onAuthStateChanged for handling user session
export const onAuthStateChangedListener = (callback) => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      console.log('User signed in:', user);
      callback(user); // Pass the authenticated user to the callback
    } else {
      console.log('No user signed in');
      callback(null); // Pass null if no user is authenticated
    }
  });
  return unsubscribe;
};
