import { Box, Button, CircularProgress, Rating, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { collection, doc, getDocs, limit, query, setDoc } from 'firebase/firestore';
import { FC, useEffect, useState } from 'react';
import ReviewDialog from "../../../../components/EFModal";
import { auth, firestore } from '../../../../firebase';

interface ProfileReviewsProps {
  id: string;
}

const Reviews: FC<ProfileReviewsProps> = ({ id }) => {
  const [review, setReviews] = useState<any[]>([]);
  const [value, setValue] = useState<number | null>(3);
  const [hover, setHover] = useState<number | null>(-1);
  const [loading, setLoading] = useState(true);
  const [noReviews, setNoReviews] = useState(false);
  const [open, setOpen] = useState(false);
  const [reviewText, setReviewText] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setReviewText(''); 
    setValue(0); 
  };

  const handleSubmit = async () => {
    if (value && reviewText) {
      const reviewRef = doc(collection(firestore, "vendors", id, "Reviews"));
      await setDoc(reviewRef, {
        review_user_id: auth.currentUser?.uid, // Replace with the actual user name if available
        review_rating: value,
        review_desc: reviewText,
        review_date: new Date(),
      });
      handleClose();
      getReviews(); // Fetch reviews again to update the list
    }
  };

  function insertBreaks(text: string, maxLength: number) {
    const regex = new RegExp(`.{1,${maxLength}}`, 'g');
    return text.match(regex)?.join(' ') || text;
  }

  async function getReviews() {
    try {
      const q = query(collection(firestore, "vendors", id, "Reviews"), limit(5));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log('No reviews found');
        setNoReviews(true);
        setLoading(false);
        return;
      }

      const entries = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setReviews(entries);
      setLoading(false);
      setNoReviews(false);
    } catch (error) {
      console.error("Error fetching document: ", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getReviews();
  }, [id]);

  const labels: { [index: string]: string } = {
    0: 'No Rating',
    1: 'Terrible',
    2: 'Poor',
    3: 'Okay',
    4: 'Good',
    5: 'Excellent',
  };

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  if (loading) {
    return (
      <Grid container spacing={1} padding={4} justifyContent="left" alignItems="center" height="100%">
      <Box>
          <Grid item>
            <h3 className='subtitle'>Loading Reviews...</h3>
            <CircularProgress />
          </Grid>
        </Box>
      </Grid>
    );
  } else if (noReviews) {
    return (
      <Grid container spacing={1} padding={4} justifyContent="left" alignItems="center" height="100%">
      <Box>
          <Grid item>
            <h3 className='subtitle'>Reviews</h3>
            <hr />
            <Typography className='subtitle'>No reviews have been left yet. <a onClick={handleClickOpen}>Be the first!</a></Typography>
            <ReviewDialog 
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              value={value}
              setValue={setValue}
              reviewText={reviewText}
              setReviewText={setReviewText}
              labels={labels}
              getLabelText={getLabelText}
              authUserId={auth.currentUser?.uid} // pass user ID to check sign-in status
            />
          </Grid>
        </Box>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={1} padding={4} justifyContent="left" alignItems="center" height="100%">
      <Box style={{ position: 'relative' }}> {/* Set relative positioning for the container */}
          <Grid item>
            <h3 className='subtitle'>Reviews</h3>
            <hr />
                  {/* Button Positioned at the Bottom Right */}
        <Button
          variant="contained"
          color="info"
          onClick={handleClickOpen}
          style={{
            zIndex: 10, // Ensure it stays on top
          }}
        >
          Submit a Review
        </Button>
            <ReviewDialog 
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              value={value}
              setValue={setValue}
              reviewText={reviewText}
              setReviewText={setReviewText}
              labels={labels}
              getLabelText={getLabelText}
              authUserId={auth.currentUser?.uid} // pass user ID to check sign-in status
            />
          </Grid>
        </Box>

        {/* Reviews List */}
        {review.map((review, i) => (
          <Box key={i}>
            <Grid item xs={12} lg={12} spacing={5} justifyContent="left" mx="auto">
              <Grid item xs={2} lg={2}>
                <img width="100%" src={review?.pictureURL} alt={review?.review_name} />
              </Grid>
              <Grid item xs={10} lg={10}>
                <h4 className='subtitle'>{review?.review_name}</h4>
                <Box sx={{ width: 300, display: 'flex', alignItems: 'center' }}>
                  <Rating
                    value={review?.review_rating}
                    getLabelText={getLabelText}
                    readOnly
                  />
                  {value !== null && (
                    <Box sx={{ ml: 2 }}>{labels[review?.review_rating]}
                      <div className="rating"></div>
                    </Box>
                  )}
                </Box>
                <div className="italic">
                  {review?.review_date.toDate().toDateString()}
                </div>
                <div className='review-text'>{insertBreaks(review?.review_desc, 35)}</div>
              </Grid>
            </Grid>
          </Box>
        ))}

  
      </Grid>
    );
  }
};

export default Reviews;
