import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { onAuthStateChanged } from 'firebase/auth';
import {
  arrayRemove,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc
} from "firebase/firestore";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import defaultImage from "../../../../assets/images/2025eventflowbg.png";
import EFCart from '../../../../components/EFCart';
import { businessTypeDropdown } from '../../../../ef-constants';
import { auth, firestore } from "../../../../firebase";
import ExpediaAffiliateButton from '../../../../layouts/sections/components/Affiliates/expedia';
import BaseLayout from "../../../../layouts/sections/components/BaseLayout";
import EditEvent from './components/EditEvent';
import EFCheckInButton from './components/EFCheckInButton';
import EFDashboardButton from './components/EFDashboardButton';
import EventDetailsInfo from './components/EventDetailsInfo';
import Itinerary from './components/Itinerary';
import ViewVendors from './components/ViewVendors';

function EventDetails(props) {
  const { eventId: routeEventId } = useParams();
  const eventId = props.eventId || routeEventId;
  const navigate = useNavigate();

  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOwner, setIsOwner] = useState(false);
  const [error, setError] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [guestList, setGuestList] = useState([]);
  const [newGuestName, setNewGuestName] = useState("");
  const [editingGuestIndex, setEditingGuestIndex] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openVendorModal, setOpenVendorModal] = useState(false);
  const [openEditEventDialog, setOpenEditEventDialog] = useState(false);
  const [daysLeft, setDaysLeft] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showCheckIn, setShowCheckIn] = useState(false);

  // Track current user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  // Fetch event
  useEffect(() => {
    if (!eventId) {
      setError("Event ID is required");
      return;
    }

    const fetchEvent = async () => {
      const docRef = doc(firestore, "Events", eventId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const result = docSnap.data();
        setEvent(result);
        setGuestList(result.guests || []);

        const eventDate = new Date(result.event_date);
        const currentDate = new Date();
        const timeDiff = eventDate - currentDate;
        const daysRemaining = Math.floor(timeDiff / (1000 * 3600 * 24));
        setDaysLeft(daysRemaining);

        if (currentDate >= eventDate) {
          setShowCheckIn(true);
        }

        // Support multiple owners
        if (
          currentUser &&
          Array.isArray(result.ownerId) &&
          result.ownerId.includes(currentUser.uid)
        ) {
          setIsOwner(true);
        }

        setLoading(false);
      } else {
        setError("Event not found.");
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId, currentUser]);

  useEffect(() => {
    const fetchVendors = async () => {
      if (!eventId) return;
      const vendorCollectionRef = collection(firestore, "Events", eventId, "Vendors");
      const vendorSnapshot = await getDocs(vendorCollectionRef);
      const vendorIds = vendorSnapshot.docs
        .map(doc => doc.data().vendor_id)
        .filter(Boolean);

      const vendorDetails = [];
      for (const vendorId of vendorIds) {
        const vendorDocRef = doc(firestore, "vendors", vendorId);
        const vendorDocSnap = await getDoc(vendorDocRef);
        if (vendorDocSnap.exists()) {
          vendorDetails.push({ id: vendorDocSnap.id, ...vendorDocSnap.data() });
        }
      }
      setVendorList(vendorDetails);
    };

    if (event) fetchVendors();
  }, [event]);

  const handleRemoveVendor = async (vendorId) => {
    try {
      const eventDocRef = doc(firestore, "Events", eventId);
      await updateDoc(eventDocRef, {
        vendors: arrayRemove(vendorId),
      });
      setVendorList(prev => prev.filter((vendor) => vendor.id !== vendorId));
    } catch (error) {
      console.error("Error removing vendor: ", error);
    }
  };

  const handleDeleteEvent = async () => {
    try {
      const eventDocRef = doc(firestore, "Events", eventId);
      await deleteDoc(eventDocRef);
      navigate("/events");
    } catch (error) {
      console.error("Error deleting event: ", error);
    }
  };

  const handleAddGuest = async () => {
    if (newGuestName.trim() === "") return;
    const updatedGuestList = [...guestList, newGuestName];
    try {
      await updateDoc(doc(firestore, "Events", eventId), { guests: updatedGuestList });
      setGuestList(updatedGuestList);
      setNewGuestName("");
    } catch (error) {
      console.error("Error adding guest: ", error);
    }
  };

  const handleDeleteGuest = async (guestName) => {
    const updatedGuestList = guestList.filter(guest => guest !== guestName);
    try {
      await updateDoc(doc(firestore, "Events", eventId), { guests: updatedGuestList });
      setGuestList(updatedGuestList);
    } catch (error) {
      console.error("Error deleting guest: ", error);
    }
  };

  const handleEditGuest = (index) => {
    setEditingGuestIndex(index);
    setNewGuestName(guestList[index]);
  };

  const handleSendInvite = (guestName) => {
    alert(`Invite sent to ${guestName}`);
  };

  const groupedVendors = businessTypeDropdown.reduce((acc, type) => {
    acc[type] = vendorList.filter(v => v.vendor_type === type);
    return acc;
  }, {});

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>{error}</Typography>;

  return (
    <BaseLayout title="Events" breadcrumb={[{ label: "Events", route: "/events" }, { label: "Event Details", route: `/events/${eventId}` }]}>
      <Box sx={{ backgroundColor: '#F2F2F2', boxShadow: 3, p: 3 }}>
        {/* Header */}
        <Box
          sx={{
            position: 'relative',
            height: { xs: 200, sm: 300, md: 600 },
            backgroundImage: `url(${event.event_image || defaultImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            marginBottom: 3,
            marginTop: 5,
            cursor: event.event_image ? 'pointer' : 'default'
          }}
          onClick={event.event_image ? () => setOpenImageDialog(true) : undefined}
        >
          <Box sx={{ position: 'absolute', bottom: '70px', left: '20px', color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: '10px 20px', borderRadius: '5px' }}>
            <Typography variant={isMobile ? "h6" : "h4"}>{event.event_name}</Typography>
          </Box>
          <Box sx={{ position: 'absolute', bottom: '20px', left: '20px', color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: '10px 20px', borderRadius: '5px' }}>
            <Typography variant="h6">{daysLeft !== null ? daysLeft : 'Calculating...'} Days Left</Typography>
          </Box>
        </Box>

        {/* Image Modal */}
        <Dialog open={openImageDialog} onClose={() => setOpenImageDialog(false)} maxWidth="md" fullWidth>
          <DialogContent sx={{ p: 0 }}>
            <img src={event.event_image} alt="Event" style={{ width: "100%", height: "auto", objectFit: "contain" }} />
          </DialogContent>
        </Dialog>

        {isOwner && (
          <EFDashboardButton eventId={eventId} variant="contained" size="large" fullWidth sx={{ mb: 2 }} />
        )}

        <EventDetailsInfo
          budget={Number(event.event_budget)}
          cost={event.eventPrice}
          isOwner={isOwner}
          capacity={event.event_capacity}
          city={event.event_city}
          date={event.event_date}
        />

        <Grid container padding={5}>
          <Grid item>
            {event.event_address && <h4>Address: {event.event_address}</h4>}
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {event.event_description}
            </Typography>
          </Grid>

          {event.event_checkin && showCheckIn && (
            <EFCheckInButton eventId={eventId} variant="contained" size="large" fullWidth />
          )}

          {event.event_ticketed && (
            <Button
              className="ef-cta-btn ef-btn orange"
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => {
                if (event.event_ticketLink) {
                  window.open(event.event_ticketLink, '_blank');
                }
              }}
            >
              Buy Tickets
            </Button>
          )}
        </Grid>

        <Itinerary vendors={vendorList} owner={isOwner} event_date={event.event_date} eventId={eventId} />

        {isOwner && (
          <Box sx={{ marginTop: 3 }}>
            <Typography variant="h5">Guests</Typography>
            <List>
              {guestList.map((guest, index) => (
                <ListItem key={index}>
                  <ListItemText primary={guest} />
                  <Button onClick={() => handleDeleteGuest(guest)}>Delete</Button>
                  <Button onClick={() => handleEditGuest(index)}>Edit</Button>
                  <Button onClick={() => handleSendInvite(guest)}>Send Invite</Button>
                </ListItem>
              ))}
            </List>
            <TextField value={newGuestName} onChange={(e) => setNewGuestName(e.target.value)} label="Add Guest" />
            <Button className='ef-sm-btn' onClick={handleAddGuest}>Add</Button>
          </Box>
        )}

        <Box sx={{ marginTop: 3 }}>
          <Button className='ef-sm-btn' onClick={() => setOpenVendorModal(true)}>
            View Vendors
          </Button>
          <ViewVendors
            open={openVendorModal}
            onClose={() => setOpenVendorModal(false)}
            isOwner={isOwner}
            event={event}
            eventId={eventId}
            vendorList={vendorList}
            handleRemoveVendor={handleRemoveVendor}
          />
        </Box>

        {isOwner && (
          <>
            <EditEvent eventId={eventId} eventData={event} onSave={() => { }} onCancel={() => { }} />
            <Button variant="outlined" className='delete' onClick={() => setOpenDeleteDialog(true)} sx={{ marginTop: 2 }}>
              Delete Event
            </Button>
            <EFCart />
          </>
        )}

        <br />
        <a rel="sponsored" href="https://www.awin1.com/cread.php?s=3220231&v=6776&q=445423&r=1825334">
          <img src="https://www.awin1.com/cshow.php?s=3220231&v=6776&q=445423&r=1825334" border="0" />
        </a>

        <ExpediaAffiliateButton eventCity={event.event_city} eventDate={event.event_date} eventImage={event.event_image} />

        <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
          <DialogTitle>Delete Event</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this event?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
            <Button onClick={handleDeleteEvent} color="primary">Delete</Button>
          </DialogActions>
        </Dialog>

        {isOwner && (
          <Fab color="primary" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => navigate(`/vendors/${event.event_city}`)}>
            Browse Vendors
          </Fab>
        )}
      </Box>
    </BaseLayout>
  );
}

EventDetails.propTypes = {
  eventId: PropTypes.string,
};

export default EventDetails;
