import { collection, doc, getDocs, query, updateDoc, where } from '@firebase/firestore';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, List, ListItem, ListItemText, Snackbar, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { firestore } from '../../../../../firebase';
import UploadPhoto from '../../../../../functions/UploadPhoto';

const EditEvent = ({ eventId, eventData, onSave, onCancel }) => {
  const [openEditEventDialog, setOpenEditEventDialog] = useState(false);
  const [editableEventName, setEditableEventName] = useState(eventData.event_name || '');
  const [editableEventAddress, setEditableEventAddress] = useState(eventData.event_address || '');
  const [editableEventDescription, setEditableEventDescription] = useState(eventData.event_description || '');
  const [editableEventImage, setEditableEventImage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [editableEventDate, setEditableEventDate] = useState(eventData.event_date || '');
  const [editableEventCheckin, setEditableEventCheckin] = useState(eventData.event_checkin || false);
  const [editableEventBudget, setEditableEventBudget] = useState(eventData.event_budget || '');
  const [editableEventCapacity, setEditableEventCapacity] = useState(eventData.event_capacity || '');
  const [editableEventCity, setEditableEventCity] = useState(eventData.event_city || '');
  const [editableEventPublic, setEditableEventPublic] = useState(eventData.isPublic || false);
  const [editableEventPrice, setEditableEventPrice] = useState(eventData.eventPrice || '');
  const [vendorSearchQuery, setVendorSearchQuery] = useState('');
  const [matchedVendors, setMatchedVendors] = useState([]);
  const [selectedOwners, setSelectedOwners] = useState(eventData.ownerId || []);

  useEffect(() => {
    if (eventData) {
      setEditableEventName(eventData.event_name);
      setEditableEventAddress(eventData.event_address);
      setEditableEventDescription(eventData.event_description);
      setEditableEventDate(eventData.event_date);
      setEditableEventBudget(eventData.event_budget);
      setEditableEventCheckin(eventData.event_checkin);
      setEditableEventCapacity(eventData.event_capacity);
      setEditableEventCity(eventData.event_city);
      setEditableEventPublic(eventData.isPublic);
      setEditableEventPrice(eventData.eventPrice || '');
      setSelectedOwners(eventData.ownerId || []);
    }
  }, [eventData]);

  const handlePhotoUpload = async (photoUrl, eventId) => {
    try {
      const eventsRef = doc(firestore, 'Events', eventId);
      await updateDoc(eventsRef, { event_image: photoUrl });
      setEditableEventImage(photoUrl);
      setSuccessMessage('Event updated with photo!');
    } catch (error) {
      setErrorMessage('Error occurred while uploading the photo.');
      console.error(error);
    }
  };

  const handleVendorSearch = async () => {
    try {
      const q = query(
        collection(firestore, 'vendors'),
        where('vendor_name', '>=', vendorSearchQuery),
        where('vendor_name', '<=', vendorSearchQuery + '\uf8ff')
      );
      const snapshot = await getDocs(q);
      const results = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMatchedVendors(results);
    } catch (error) {
      console.error('Error searching vendors:', error);
    }
  };

  const toggleOwnerSelection = (uid) => {
    if (selectedOwners.includes(uid)) {
      setSelectedOwners(prev => prev.filter(id => id !== uid));
    } else {
      setSelectedOwners(prev => [...prev, uid]);
    }
  };

  const handleSaveEventEdit = async () => {
    try {
      const updatedEvent = {
        event_name: editableEventName || '',
        event_address: editableEventAddress || '',
        event_description: editableEventDescription || '',
        event_date: editableEventDate || '',
        event_budget: editableEventBudget || '',
        event_capacity: editableEventCapacity || '',
        event_city: editableEventCity || '',
        isPublic: editableEventPublic,
        event_checkin: editableEventCheckin,
        eventPrice: editableEventPrice,
        ownerId: selectedOwners
      };

      const docRef = doc(firestore, 'Events', eventId);
      await updateDoc(docRef, updatedEvent);
      setSuccessMessage('Event updated successfully!');
      setOpenEditEventDialog(false);
    } catch (error) {
      console.error('Error updating event:', error);
      setErrorMessage('Failed to update the event.');
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        className="ef-sm-btn"
        onClick={() => setOpenEditEventDialog(true)}
        sx={{ marginTop: 2 }}
      >
        Edit Event
      </Button>

      <Dialog open={openEditEventDialog} onClose={() => setOpenEditEventDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Event Details</DialogTitle>
        <DialogContent>
          <TextField fullWidth label="Event Name" value={editableEventName} onChange={(e) => setEditableEventName(e.target.value)} sx={{ mb: 2 }} />
          <TextField fullWidth label="Event Address" value={editableEventAddress} onChange={(e) => setEditableEventAddress(e.target.value)} sx={{ mb: 2 }} />
          <TextField fullWidth label="Event Description" multiline rows={4} value={editableEventDescription} onChange={(e) => setEditableEventDescription(e.target.value)} sx={{ mb: 2 }} />
          <TextField fullWidth label="Event Date" type="date" value={editableEventDate} onChange={(e) => setEditableEventDate(e.target.value)} sx={{ mb: 2 }} InputLabelProps={{ shrink: true }} />
          <TextField fullWidth label="Event Budget" type="number" value={editableEventBudget} onChange={(e) => setEditableEventBudget(e.target.value)} sx={{ mb: 2 }} />
          <TextField fullWidth label="Event Capacity" type="number" value={editableEventCapacity} onChange={(e) => setEditableEventCapacity(e.target.value)} sx={{ mb: 2 }} />
          <TextField fullWidth label="Event City" value={editableEventCity} onChange={(e) => setEditableEventCity(e.target.value)} sx={{ mb: 2 }} />
          <TextField fullWidth label="Event Price (if ticketed)" type="number" value={editableEventPrice} onChange={(e) => setEditableEventPrice(e.target.value)} sx={{ mb: 2 }} />

          <UploadPhoto id={eventId} source="events" onUploadComplete={(photoUrl) => handlePhotoUpload(photoUrl, eventId)} />

          <FormControlLabel control={<Checkbox checked={editableEventCheckin} onChange={() => setEditableEventCheckin(!editableEventCheckin)} />} label="Enable Check-in" />
          <FormControlLabel control={<Checkbox checked={editableEventPublic} onChange={() => setEditableEventPublic(!editableEventPublic)} />} label="Make Event Public" />

          <Box mt={3}>
            <Typography variant="h6">Add Co-Owners by Vendor Name</Typography>
            <TextField fullWidth label="Search Vendors" value={vendorSearchQuery} onChange={(e) => setVendorSearchQuery(e.target.value)} sx={{ mt: 1 }} />
            <Button onClick={handleVendorSearch} variant="outlined" sx={{ mt: 1 }}>Search</Button>
            <List>
              {matchedVendors.map((vendor) => (
                <ListItem key={vendor.id} button onClick={() => toggleOwnerSelection(vendor.vendor_own)}>
                  <ListItemText primary={vendor.vendor_name} secondary={vendor.vendor_email} />
                  <Checkbox checked={selectedOwners.includes(vendor.vendor_own)} />
                </ListItem>
              ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditEventDialog(false)} color="secondary">Cancel</Button>
          <Button onClick={handleSaveEventEdit} color="primary">Save</Button>
        </DialogActions>

        <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')} message={errorMessage} />
        <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')} message={successMessage} />
        <ToastContainer position="bottom-center" autoClose={8000} hideProgressBar />
      </Dialog>
    </>
  );
};

EditEvent.propTypes = {
  eventId: PropTypes.string.isRequired,
  eventData: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditEvent;
