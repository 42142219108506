import { Box, Card, CardContent, CardMedia, Grid, TextField, Typography } from '@mui/material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MKButton from '../../../../components/MKButton';
import { auth, firestore } from "../../../../firebase";
import BaseLayout from "../../../../layouts/sections/components/BaseLayout";

function MyEvents() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const navigate = useNavigate();

  // Fetch events for the current user
  async function getEvents() {
    const q = query(collection(firestore,  "Events"), where("ownerId", "array-contains", auth.currentUser.uid));
   // const q = query(eventsCollection, where("ownerId", "array-contains", auth.currentUser.uid));


    const querySnapshot = await getDocs(q);
    try {
      const entries = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(entries);
      setLoading(false); // Set loading to false after events are fetched
    } catch {
      setError("Error fetching documents");
      setLoading(false);
    }
  }

  useEffect(() => {
    if (auth.currentUser || Cookies.get('user')) {
      getEvents();
    } else {
      alert('You must sign in to create an event.');
      navigate('/sign-in'); 
    }
  }, [auth.currentUser]);

  // Handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Format the event date to avoid timezone offset
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toISOString().split('T')[0];  // Format to 'YYYY-MM-DD'
  };

  // Filter events based on search query
  const filteredEvents = events.filter((event) => {
    const searchTerm = searchQuery.toLowerCase();
    return (
      event.event_name.toLowerCase().includes(searchTerm) ||
      event.event_city.toLowerCase().includes(searchTerm) ||
      formatDate(event.event_date).toLowerCase().includes(searchTerm)  // Use formatted date
    );
  });

  return (
    <BaseLayout
      title="Events"
      breadcrumb={[
        { label: "Events", route: "/events" },
      ]}
    >
{events.length > 4 ? (
  <Box mb={3}>
    {/* Search Bar */}
    <TextField
      label="Search Events"
      variant="outlined"
      fullWidth
      value={searchQuery}
      onChange={handleSearchChange}
      placeholder="Search by name, city, or date"
    />
  </Box>
) : null}  {/* Optionally, you can render something else here */}
     

      {filteredEvents.length > 0 ? (
        <Grid container spacing={3}>
          {filteredEvents.map(event => (
            <Grid item xs={12} sm={6} md={4} key={event.event_id}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  '&:hover': {
                    boxShadow: 3,
                    transform: 'scale(1.05)',
                    transition: 'transform 0.3s ease-in-out',
                  },
                }}
                onClick={() => navigate(`/events/${event.event_id}`)}
              >
                {/* Event Image (only if available) */}
                {event.event_image ? (
                  <CardMedia
                    component="img"
                    alt={event.event_name}
                    height="200"
                    image={event.event_image}
                    sx={{ objectFit: 'cover' }}
                  />
                ) : (
                  <Box
                    sx={{
                      height: '200px',
                      backgroundColor: '#f5f5f5',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#aaa',
                    }}
                  >
                    No Image Available
                  </Box>
                )}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="div" gutterBottom>
                    {event.event_name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Date:</strong> {formatDate(event.event_date)} {/* Use formatted date */}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>City:</strong> {event.event_city}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item>
            <MKButton
              variant="contained"
              color="primary"
              onClick={() => navigate("/createnewevent")}
            >
              No Events Found. Create an Event
            </MKButton>
          </Grid>
        </Grid>
      )}

    </BaseLayout>
  );
}

export default MyEvents;
