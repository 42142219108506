import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

// Custom progress bar for the Budget card
const BudgetProgress = ({ spent, budget, cost }) => {
  const progress = (spent / budget) * 100;
  const normalWidth = progress > 100 ? 100 : progress;
  const extraWidth = progress > 100 ? progress - 100 : 0;

  return (
    <Box sx={{ mt: 2, px: 2 }}>
      {/* Container for the progress bar */}
      <Box
        sx={{
          position: 'relative',
          height: '10px',
          backgroundColor: '#ddd',
          borderRadius: '5px',
          overflow: 'visible',
        }}
      >
        {/* Normal progress fill */}
        <Box
          sx={{
            height: '10px',
            borderRadius: '5px',
            width: `${normalWidth}%`,
            backgroundColor: 'green',
            transition: 'width 0.3s ease-in-out',
          }}
        />
        {/* Extra fill if the spent amount exceeds the budget */}
        {extraWidth > 0 && (
          <Box
            sx={{
              position: 'absolute',
              left: '100%',
              top: 0,
              height: '10px',
              width: `${extraWidth}%`,
              backgroundColor: 'red',
              transition: 'width 0.3s ease-in-out',
            }}
          />
        )}
      </Box>
      <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
        {progress.toFixed(0)}%
      </Typography>
    </Box>
  );
};

BudgetProgress.propTypes = {
  spent: PropTypes.number,
  budget: PropTypes.number.isRequired,
  cost: PropTypes.number,
};

// EventDetailsInfo Component to display event data dynamically
const EventDetailsInfo = ({ city, capacity, budget, date, spent, isOwner, cost }) => {
  const [event, setEvent] = useState(null); // (Not used in this example)
  const [loading, setLoading] = useState(true); // (Not used in this example)

  // If budget is 0 or falsy, then display "None" instead of a monetary value
  let formattedBudget;
  if (budget === 0 || !budget) {
    formattedBudget = "None";
  } else {
    formattedBudget = isOwner ? `$${budget}` : `$${cost}`;
  }

  // Format the date into YYYY-MM-DD
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toISOString().split('T')[0];
  };

  // Common styling for circular cards
  const circularCardStyle = {
    textAlign: 'center',
    paddingY: '1em',
    borderRadius: '50%',
    overflow: 'hidden',
    height: 250,
    width: 250,
    mx: 'auto',
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={3} justifyContent="center">
        {/* Date Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ ...circularCardStyle, background: 'linear-gradient(to right, #CC6E32, #FF8A41)' }}>
            <CardMedia
              component="img"
              alt="Date"
              image="https://img.icons8.com/ios/452/calendar.png"
              sx={{
                objectFit: 'contain',
                maxHeight: '100px',
                margin: 'auto',
              }}
            />
            <CardContent>
              <Typography variant="h6" color="#fff">
                Date
              </Typography>
              <Typography variant="body2" color="#fff">
                {formatDate(date)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* City Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ ...circularCardStyle, background: 'linear-gradient(to right, #CC6E32, #FF8A41)' }}>
            <CardMedia
              component="img"
              alt="City"
              image="https://img.icons8.com/ios/452/marker.png"
              sx={{
                objectFit: 'contain',
                maxHeight: '100px',
                margin: 'auto',
              }}
            />
            <CardContent>
              <Typography variant="h6" color="#fff">
                City
              </Typography>
              <Typography variant="body2" color="#fff">
                {city}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Capacity Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ ...circularCardStyle, background: 'linear-gradient(to right, #CC6E32, #FF8A41)' }}>
            <CardMedia
              component="img"
              alt="Capacity"
              image="https://media.istockphoto.com/id/1533834784/vector/symbol-people-icon-vector-graphics.jpg?s=612x612&w=0&k=20&c=Tq8HHi5fDpsCtkjyDFNAQ0K2sPjhA_ZulcltxgJG-ZM="
              sx={{
                objectFit: 'contain',
                maxHeight: '100px',
                margin: 'auto',
              }}
            />
            <CardContent>
              <Typography variant="h6" color="#fff">
                Capacity
              </Typography>
              <Typography variant="body2" color="#fff">
                {capacity}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Budget Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ ...circularCardStyle, background: 'linear-gradient(to right, #145883, #1e6c97)' }}>
            <CardMedia
              component="img"
              alt="Budget"
              image="https://img.icons8.com/ios/452/money-bag.png"
              sx={{
                objectFit: 'contain',
                maxHeight: '100px',
                margin: 'auto',
              }}
            />
            <CardContent>
              <Typography variant="h6" color="#fff">
                Cost
              </Typography>
              <Typography variant="body2" color="#fff">
                {formattedBudget}
              </Typography>
              {/* Only show the progress bar if a budget exists and if the user is the owner */}
              {formattedBudget !== "None" && isOwner && (
                <BudgetProgress spent={spent} budget={budget} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

EventDetailsInfo.propTypes = {
  city: PropTypes.string.isRequired,        // City name as a string
  capacity: PropTypes.string.isRequired,      // Capacity as a string
  budget: PropTypes.number.isRequired,        // Budget as a number
  date: PropTypes.string.isRequired,          // Date as a string
  spent: PropTypes.number,         // Amount spent as a number
  isOwner: PropTypes.bool.isRequired,         // Whether the user is the owner of the event
  cost: PropTypes.number,         // Cost as a number
};

export default EventDetailsInfo;
